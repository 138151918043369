[class*="c-slick__button"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    display: block;
    width: rem(40);
    height: rem(40);
    padding: 0;
    border-radius: 50%;
    border: rem(1) solid $grey--light;
    color: $mainFont;
    background: transparent;
    outline: none;
    text-align: center;
    transition: $ease;

    @include bp-lt($small) {
        background: $white;
    }

    &:hover, &:focus {
        color: $white;
        background: $mainBranding;
    }

    &[class*="--prev"] {
        left: rem(-65);

        @include bp-lt($small) {
            left: 0;
        }

        [class*="c-fonticon"] {
            &:before { left: rem(-1); }
        }
    }

    &[class*="--next"] {
        right: rem(-65);

        @include bp-lt($small) {
            right: 0;
        }

        [class*="c-fonticon"] {
            &:before { right: rem(-1); }
        }
    }

    [class*="c-fonticon"] {
        &:before { top: rem(1); }
    }
}

.slick-slide {
    img { margin: 0 auto; }
}
