.c-store__table {
    margin-top: rem(45);
    max-width: rem(620);

    @include bp-lt($medium) { margin-top: rem(30); }

    table {
        margin-top: rem(20);
        border-color: $grey--light;

        tr { background: $white; }
    }

    td {
        padding-top: 0;
        padding-bottom: 0;
        border: rem(1) solid $grey--light;
        vertical-align: middle;
        text-align: center;
    }

    .c-title--medium {
        line-height: 1em;
    }
}

.c-store__price {
    font-size: rem(16);
    padding-left: rem(35);
    padding-right: rem(35);
    opacity: 0.6;
    white-space: nowrap;

    @include bp-lt($medium) {
        padding-left: rem(20);
        padding-right: rem(20);
        font-size: rem(14);
    }

    @include bp-lt($small) {
        font-size: rem(12);
        padding-left: rem(10);
        padding-right: rem(10);
    }

    .is-leader & {
        font-size: rem(23);
        font-weight: 900;
        background: $salmon--light;
        opacity: 1;

        @include bp-lt($medium) {
            font-size: rem(20);
        }

        @include bp-lt($small) {
            font-size: rem(16);
        }
    }
}

.c-store__url {
    background: $grey--lighter;
    padding-left: 0;
    padding-right: 0;

    [class*="c-button"] {
        vertical-align: middle;
        height: rem(65);
        width: 100%;
        background-color: transparent;
        color: $mainFont;
        text-decoration: none;
        font-size: rem(12);
        border-radius: 0;

        @include bp-lt($small) {
            font-size: rem(8);
            min-width: 0;            
        }

        &:hover, &:focus {
            color: $white;
            background-color: $mainBranding;
        }
    }

    .is-leader & {
        [class*="c-button"] {
            color: $white;
            background: $mainBranding;
            font-size: rem(14);

            @include bp-lt($small) {
                font-size: rem(10);
            }

            &:hover, &:focus { background-color: $subBranding; }
        }
    }
}

.c-store__logo {
    min-width: rem(130);

    @include bp-lt($small) {
        padding-left: rem(5);
        padding-right: rem(5);
    }
}
