.o-paginate {
    text-align: center;
    margin-top: rem(20);

    @include bp-lt($medium) { margin-top: rem(10); }

    @include bp-lt($small) { margin-top: rem(5); }

    ul { list-style: none; margin: 0; }

    li {
        display: inline-block;
        margin-left: rem(-4);

        &:first-child { margin-left: 0; }
    }

    a, span {
        text-decoration: none;
        font-size: rem(13);
        color: $mainFont;
        margin: 0 rem(10);
        display: inline-block;

        span { margin: 0; }
    }

    a {
        &:hover, &:focus { color: $mainBranding; }
    }

    .current {
        font-weight: 900;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: rem(5) auto 0 auto;
            width: rem(4);
            height: rem(4);
            border-radius: 50%;
            background: $mainBranding;
        }
    }

    .prev {
        margin-right: rem(40);
    }

    .next {
        margin-left: rem(40);
    }
}
