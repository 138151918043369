@mixin stickyLogo {
    float: left;
    width: rem(145);
    height: rem(35);
    margin-right: rem(40);
    margin-top: rem(5);
}

@mixin stickySearch {
    float: left;
    margin: 0;
    width: calc(100% - 325px);
}

.c-sticky__holder {
    box-shadow: 0 0 0 transparent;
    //transition: $ease;

    &.stuck {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        z-index: $zindex--fixed;
        box-shadow: 0 rem(5) rem(5) rgba($black, 0.1);

        @include bp-lt($medium) { transition: $bezier; }

        .is-fixed & {
            @include bp-lt($medium) {
                right: rem(280);
            }
        }

        .o-header__container { max-width: $layoutWidth; }

        .o-header {
            transition: none;
            background: $white;

            &, .front & { height: rem(46); }
        }

        .c-logo__header {
            transition: none;

            &, .front & {
                @include stickyLogo();

                @include bp-lt($medium) {
                    margin-right: rem(15);
                }

                @include bp-lt($small) {
                    margin-right: rem(0);
                }

                @include bp-lt($xsmall) {
                    margin-top: rem(10);
                    width: rem(90);
                    height: rem(22);
                }
            }
        }

        .c-search {
            transition: none;

            &, .front & {
                @include stickySearch();
                box-shadow: none;

                input[type="text"], [class*="c-button"] {
                    height: rem(46);

                    @include bp-lt($small) {
                        height: rem(35);
                    }
                }

                input[type="text"] {
                    border-radius: 0;
                    box-shadow: rem(-3) 0 rem(5) 0 rgba($black, 0.1);
                }

                [class*="c-button"] {
                    border-radius: 0;
                    background: $subBranding;
                    font-size: rem(15);

                    &:hover, &:focus { background: $salmon--light; }

                    [class*="c-fonticon"] {
                        @include bp-lt($small) { font-size: rem(18); }

                        &:before {
                            @include bp-lt($small) { top: rem(-2); }
                        }
                    }
                }
            }

            .not-front & {
                width: calc(100% - 554px);

                @include bp-lt($desktop) {
                    width: calc(100% - 375px);
                }

                @include bp-lt($medium) {
                    width: calc(100% - 405px) !important;
                }

                @include bp-lt($xmedium) {
                    width: calc(100% - 395px) !important;
                }

                @include bp-lt($small) {
                    width: 100% !important;
                }
            }
        }

        .c-button__advancedSearch {
            display: none;

            &:hover, &:focus {
                @include bp-lt($xmedium) {
                    background-color: $mainBranding;
                }
            }

            .not-front & {
                display: inline-block;
                height: rem(46);
                margin-left: rem(15);

                @include bp-lt($desktop) {
                    width: rem(52);
                    height: rem(46);
                    min-width: 0;
                    background: darken($salmon--darker, 5%);
                    border-radius: 0;
                    position: relative;
                    margin-left: 0;
                    overflow: hidden;
                }

                @include bp-lt($medium) {
                    margin-left: rem(-1);
                }

                @include bp-lt($mobileS) {
                    position: absolute;
                    top: 0;
                    left: calc(100% - 195px);
                }

                span {
                    @include bp-lt($desktop) {
                        width: 100%;
                        height: 100%;
                        background: none;
                        margin: 0;
                        top: rem(-4);
                    }

                    &:before {
                        @include bp-lt($desktop) {
                            content: '\EA11';
                            font-size: rem(20);
                            font-weight: bold;
                        }
                    }
                }

            }

            @include bp-lt($medium) {
                display: inline-block;
                height: rem(46);
                margin-left: rem(15);
            }

            @include bp-lt($xmedium) {
                margin-left: rem(-1);
            }

            @include bp-lt($mobileS) {
                position: absolute;
                top: 0;
                left: calc(100% - 195px);
            }
        }

        .c-social {
            top: rem(7);
            right: $gutter;

            @include bp-lt($medium) { right: rem(60); }

            @include bp-lt($mobileS) {
                top: rem(12);
                right: rem(55);
            }

            li {
                @include bp-lt($mobileS) {
                    margin: 0;
                }

                a {
                    @include bp-lt($mobileS) {
                        width: rem(22);
                        height: rem(22);
                        font-size: rem(12);
                    }
                }
            }
        }

        .o-nav {
            position: relative;
            z-index: 1;
            height: rem(45);
        }

        .c-nav__level0 {
            & > li {
                & > a {
                    transition: background-color 0.3s ease;
                    height: rem(45);

                    @include bp-lt($medium) {
                        height: rem(70);
                    }
                }
            }
        }

        .c-nav__level1 {
            top: rem(45);

            @include bp-lt($medium) { top: 0; }
        }
    }
}

.c-sticky__topbar {
    background: transparent;
}

.stuck {
    .c-sticky__topbar {
        height: rem(46);
        background: $white;

        .column {
            margin-left: 8.3%;
            width: 83.3%;

            @include bp-lt($medium) {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

.front {
    .c-sticky__topbar {
        @include bp-lt($medium) {
            height: rem(46);
            background: $white;
        }

        .column {
            @include bp-lt($medium) {
                margin-left: 0;
                width: 100%;
            }
        }
    }

    .o-header {
        @include bp-lt($small) {
            height: rem(46);
        }
    }

    .c-logo__header {
        @include bp-lt($medium) {
            margin-right: rem(15) !important;
        }

        @include bp-lt($small) {
            margin-right: 0 !important;
        }

        @include bp-lt($xsmall) {
            margin-top: rem(10) !important;
            width: rem(90) !important;
            height: rem(22) !important;
        }
    }

    .sticky-wrapper {
        @include bp-lt($medium) {
            height: rem(46) !important;
        }
    }

    .c-sticky__holder {
        @include bp-lt($medium) {
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            z-index: $zindex--fixed;
            box-shadow: 0 rem(5) rem(5) rgba($black, 0.1);
        }

        .c-button__advancedSearch {
            @include bp-lt($small) {
                margin-left: rem(-1);
            }

            @include bp-lt($medium) {
                display: inline-block;
                height: rem(46);
                margin-left: rem(15);
            }

            @include bp-lt($xmedium) {
                margin-left: 0;
            }

            @include bp-lt($mobileS) {
                position: absolute;
                top: 0;
                left: calc(100% - 195px);
            }
        }

        .c-social {
            @include bp-lt($medium) {
                right: rem(60);
                top: rem(7);
            }

            @include bp-lt($mobileS) {
                top: rem(12);
                right: rem(55);
            }

            li {
                @include bp-lt($mobileS) {
                    margin: 0;
                }

                a {
                    @include bp-lt($mobileS) {
                        width: rem(22);
                        height: rem(22);
                        font-size: rem(12);
                    }
                }
            }
        }
    }

    .c-search {
        input[type="text"], [class*="c-button"] {
            @include bp-lt($medium) {
                height: rem(46);
            }

            @include bp-lt($small) {
                height: rem(35);
            }
        }

        input[type="text"] {
            @include bp-lt($medium) {
                border-radius: 0;
                box-shadow: rem(-3) 0 rem(5) 0 rgba($black, 0.1);
            }
        }

        [class*="button"] {
            @include bp-lt($medium) {
                border-radius: 0;
                background: $subBranding;
            }
        }

        [class*="c-fonticon"] {
            @include bp-lt($small) { font-size: rem(18) !important; }

            &:before {
                @include bp-lt($small) { top: rem(-2); }
            }
        }
    }
}
