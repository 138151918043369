.c-page__header {
    text-align: center;
    position: relative;
    padding: rem(45) rem(60);

    @include bp-lt($medium) { padding: rem(30) rem(100); }
    @include bp-lt($small) { padding: rem(20) $gutter; }

    h1 {
        margin-bottom: 0;
    }
}
