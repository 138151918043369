.c-video {
    margin-bottom: rem(15);
    max-width: rem(450);

    a {
        text-decoration: none;

        .c-block__title { transition: $ease; }

        &:hover, &:focus {
            .c-block__title { color: $mainBranding; }
        }
    }

    &.c-block { margin-bottom: 0; }
}
