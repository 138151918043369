// ========================================= //
// wysiwyg
// ========================================= //
.c-wysiwyg {
    margin-bottom: rem(45);

    img {
        border: rem(1) solid $grey--light;
        margin-bottom: rem(35);

        @include bp-lt($small) {
            margin-bottom: rem(15);
        }

        &.alignright,
        &.align-right {
            float: right;
            margin-left: rem(50);

            @include bp-lt($small) {
                float: none;
                margin-left: 0;
            }
        }

        &.alignleft,
        &.align-left {
            float: left;
            margin-right: rem(50);

            @include bp-lt($small) {
                float: none;
                margin-right: 0;
            }
        }

        &.aligncenter,
        &.align-center {
            display: block;
            clear: both;
            margin-left: auto;
            margin-right: auto;
        }
    }

    ol, ul {
        margin-left: 0;

        li {
            margin-bottom: rem(5);
            font-weight: 300;
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;

        li {
            &:before {
                content: counter(ol-counter)'.';
                counter-increment: ol-counter;
                margin-right: rem(15);
                color: $salmon--darker;
                font-weight: 900;
            }
        }
    }

    ul {
        list-style: none;

        li {
            &:before {
                content: '';
                width: rem(5);
                height: rem(5);
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                background: $mainBranding;
                margin-right: rem(15);
                position: relative;
                top: rem(-1);
            }
        }

        ul {
            margin-left: rem(20);

            li {
                margin-bottom: 0;
                font-style: italic;

                &:before {
                    width: rem(3);
                    height: rem(3);
                    background: $mainFont;
                }
            }
        }
    }
}
