.taggle_list {
    margin: 0;
    list-style: none;
    white-space: nowrap;
    overflow-x: hidden;

    li {
        display: inline-block;
        margin: 0;

        &.taggle {
            background: none;
            padding: 0;
            line-height: rem(14);
            position: relative;
            top: rem(1);

            &:after {
                content: ',';
                display: inline-block;
                margin-right: rem(2);
            }

            button { display: none; }
        }
    }

    button {
        width: rem(10);
        height: rem(19);
        margin: 0;
        padding: 0;

        &, &hover, &:focus {
            background: none;
        }
    }
}

.taggle_text {
    font-size: rem(14);
}

.taggle_sizer { display: none; }

input.taggle_input {
    height: rem(46);

    @include bp-lt($small) {
        height: rem(38) !important;
    }

    &, &:hover, &:focus {
        border: none;
    }
}

.ui-autocomplete {
    margin: 0;
    list-style: none;
    background: $white;
    box-shadow: 0 rem(2) rem(5) rgba($black, 0.1);
    z-index: 99;
    width: calc(100% + 30px);
    max-height: rem(100);
    overflow: auto;
}

.ui-menu-item-wrapper {
    font-family: $lato;
    font-style: none;
    font-size: rem(13);
    padding: rem(3) $gutter;
    transition: $ease;
    cursor: pointer;

    &:hover {
        background: $salmon--lighter;
    }
}
