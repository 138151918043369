.c-card__item {
    margin-bottom: rem(20);

    &:last-child { float: left !important; }
}

.c-card {
    background: $white;
    border: rem(1) solid $blockBorder;
    height: 100%;
    min-height: rem(100);

    a {
        display: block;
        height: 100%;
        text-decoration: none;

        &:hover, &:focus {
            .c-card__title { color: $mainBranding; }
        }
    }

    .o-table { table-layout: fixed; }
}

.c-card__picture, .c-card__details {
    display: inline-block;
    vertical-align: middle;
    height: 100%;

    @include bp-lt($medium) {
        display: block;
        height: auto;
    }
}

.c-card__picture {
    width: calc(100% - 171px);
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: rem(100);

    @include bp-lt($large) {
        width: calc(100% - 141px);
    }

    @include bp-lt($medium) {
        width: 100%;
        border-bottom: rem(1) solid $blockBorder;
        min-height: 0;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: none;

        @include bp-lt($medium) {
            position: static;
            top: 0;
            left: 0;
            transform: none;
            max-width: 100%;
        }
    }
}

.c-card__details {
    width: rem(170);
    margin-left: rem(-4);
    padding: rem(5) rem(20);

    @include bp-lt($large) {
        width: rem(140);
        padding: rem(5) rem(10);
    }

    @include bp-lt($medium) {
        width: 100%;
        margin-left: 0;
        padding: $gutter;
        text-align: center;
    }
}

.c-card__title {
    color: $mainFont;
    font-family: $quattrocento;
    font-size: rem(20);
    line-height: rem(22);
    font-weight: 900;
    transition: $ease;

    @include bp-lt($desktop) {
        font-size: rem(18);
        line-height: rem(20);
    }
}

.c-card__subtitle {
    font-style: italic;
    font-size: rem(16);
    line-height: rem(18);

    @include bp-lt($desktop) {
        font-size: rem(14);
        line-height: rem(16);
    }
}

.js-card__equalizer {
    @include bp-lt($medium) {
        height: auto !important;
    }
}
