// ========================================= //
// search
// ========================================= //
.c-search {
    width: 100%;
    max-width: rem(825);
    margin: rem(20) auto;
    box-shadow: 0 0 rem(5) rgba($black, 0.1);
    border-radius: rem(3);
    transition: $ease;

    .front & {
        margin: rem(45) auto;
    }

    .is-searching & {
        margin: rem(20) auto;
    }

    &, .front & {
        @include bp-lt($medium) {
            @include stickySearch();
            width: calc(100% - 554px) !important;
        }

        @include bp-lt($xmedium) {
            width: calc(100% - 394px) !important;
        }

        @include bp-lt($small) {
            position: fixed;
            top: rem(46);
            right: 0;
            width: 100% !important;
            z-index: $zindex--fixed;
            background: $white;
            transition: $bezier !important;
        }

        .is-fixed & {
            @include bp-lt($small) { right: rem(280); }
        }
    }

    input[type="text"], [class*="c-button"] {
        vertical-align: top;
    }

    input[type="text"] {
        display: inline-block;
        width: 100%;
        max-width: rem(634);
        border: none;
        margin-bottom: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @include placeholder-color(lighten($grey, 20%));
    }

    [class*="c-button"] {
        margin-left: rem(-4);
        width: rem(190);
        background-color: $salmon--light;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        left: rem(1);
        line-height: rem(48);

        @include bp-lt($medium) {
            min-width: 0;
            width: rem(52);
        }

        @include bp-lt($small) {
            height: rem(40) !important;
        }

        [class*="c-fonticon"] {
            color: $mainBranding;
            font-size: rem(20);
            margin-right: rem(15);
            display: inline-block;
            vertical-align: top;

            @include bp-lt($medium) {
                color: $white;
                margin-right: 0;
            }

            &:before { font-weight: 900; }
        }
    }

    .c-label__button {
        @include bp-lt($medium) { display: none; }
    }
}

.c-search__material {
    background: $grey--lighter;
    padding: rem(20) $gutter;
    border-top: rem(1) solid $grey--light;

    .c-search {
        margin: 0 auto;
        max-width: rem(700);

        @include bp-lt($medium) {
            float: none;
            width: 100% !important;
        }

        @include bp-lt($small) {
            position: static;
        }

        [class*="c-button"] {
            @include bp-lt($medium) {
                width: rem(190);
            }

            @include bp-lt($small) {
                font-size: rem(13);
                line-height: 1.5em;
                width: rem(150);
            }

            span {
                @include bp-lt($small) {
                    display: none;
                }
            }
        }

        .easy-autocomplete {
            @include bp-lt($medium) {
                width: calc(100% - 191px) !important;
            }

            @include bp-lt($small) {
                width: calc(100% - 151px) !important;
            }
        }
    }
}
