.o-nav {
    background: $mainBranding;
    height: rem(70);

    &, .c-sticky__holder.stuck & {
        @include bp-lt($medium) {
            position: fixed;
            float: none;
            width: rem(280);
            right: rem(-280);
            left: auto;
            top: 0;
            height: 100vh;
            background: $subBranding;
            z-index: $zindex--offcanvas;
            transition: $bezier;

            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            overflow-scrolling: touch;
            -ms-overflow-style: none;
            &::-webkit-scrollbar { width: 0 !important }
        }

        .is-fixed & {
            right: 0;
        }
    }

    ul {
        list-style: none;
        margin: 0;
    }
}

.c-nav__level0 {
    height: 100%;
    text-align: center;
    position: relative;

    & > li {
        display: inline-block;
        margin-left: rem(-4);
        height: 100%;

        @include bp-lt($medium) {
            position: relative;
            width: 100%;
            max-width: 100%;
            float: none;
            height: rem(70);
            margin-left: 0;
            display: block;
        }

        &:first-child {
            margin-left: 0;

            & > a {
                &:before { display: none; }
            }
        }

        &:hover {
            & > a {
                &:before {
                    opacity: 0;
                }
            }

            & + li {
                & > a {
                    &:before {
                        opacity: 0;
                    }
                }
            }
        }

        & > a {
            display: block;
            height: rem(70);
            text-decoration: none;
            text-transform: uppercase;
            color: $white;
            font-weight: 900;
            font-size: rem(13);
            padding-left: rem(30);
            padding-right: rem(30);
            letter-spacing: rem(1);
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: rem(-2);
                width: rem(5);
                height: rem(5);
                border-radius: 50%;
                background: $subBranding;
                transition: $ease;
            }

            @include bp-lt($desktop) {
                padding-left: rem(15);
                padding-right: rem(15);
            }

            &:hover, &:focus {
                background: $subBranding;
            }
        }

    }
}

.c-nav__column {
    float: left;
    width: 50%;

    @include bp-lt($medium) {
        float: none;
        width: 100%;
    }
}

.c-nav__nosubmenu {
    height: auto !important;
}

.c-nav__viewAll {
    display: none !important;
    width: 100% !important;
    padding: 0 rem(35);
    margin-bottom: rem(15);

    @include bp-lt($desktop) {
        padding: 0 rem(14);
    }

    &:after {
        display: none;
    }

    a {
        text-transform: uppercase;
        text-decoration: underline !important;

        span {
            color: $grey--darker;
            font-style: italic;
            text-transform: none;

            &:before, &:after {
                content: '"';
            }
        }
    }
}

.touchevents {
    .c-nav__viewAll {
        display: block !important;

        @include bp-lt($medium) {
            display: none !important;
        }
    }
}

.c-nav__level0 {
    & > li {
        &:hover {
            & > a {
                background: $subBranding;

                & + .c-nav__level1 {
                    visibility: visible;
                    opacity: 1;
                    z-index: 70;
                }
            }
        }
    }
}

.c-nav__level0 {
    & > li {
        &:nth-last-child(1), &:nth-last-child(2) {
            li {
                &:hover {
                    .c-nav__level2 {
                        @include bp-gt(1024px) {
                            right: auto;
                            left: -100%;
                        }
                    }
                }
            }
        }
    }
}

.c-nav__level1 {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    width: 100%;
    left: 0;
    right: 0;
    top: rem(70);
    margin: 0;
    list-style: none;
    background: rgba($grey--lighter, 0.95);
    box-shadow: 0 rem(6) rem(8) 0 rgba($black, 0.1);
    text-align: left;
    padding: rem(25) rem(500);

    @include bp-lt($desktopXxL) {
        padding-left: rem(200);
        padding-right: rem(200);
    }

    @include bp-lt($desktopXL) {
        padding-left: rem(30);
        padding-right: rem(30);
    }

    @include bp-lt($desktop) {
        padding-left: $gutter;
        padding-right: $gutter;
    }

    @include bp-lt($medium) {
        display: block;
        position: fixed;
        width: rem(280);
        top: 0;
        left: auto;
        right: rem(-280);
        height: 100vh;
        background: $subBranding;
        z-index: $zindex--offcanvas;
        padding: 0;
        transition: $ease;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { width: 0 !important }
    }

    li {
        position: relative;
    }

    & > li {
        display: inline-block;
        width: calc(25% - 4px);
        vertical-align: top;
        padding: 0 rem(35);

        @include bp-lt($desktop) {
            padding: 0 rem(14);
        }

        @include bp-lt($medium) {
            width: 100%;
            height: auto !important;
            padding: 0;
        }

        &:after {
            content: '';
            display: block;
            width: rem(1);
            height: 100%;
            position: absolute;
            right: rem(17);
            top: 0;
            background: $grey--menu--separator;

            @include bp-lt($desktop) {
                right: rem(7);
            }

            @include bp-lt($medium) {
                display: none;
            }
        }

        &:nth-of-type(4n), &:last-of-type {
            &:after { display: none; }
        }

        & > a {
            display: inline-block;
            margin-bottom: rem(10);

            @include bp-lt($medium) {
                display: block;
                margin-bottom: 0;
            }
        }
    }

    a {
        color: $salmon--menu;
        font-weight: 300;
        font-size: rem(17);
        font-weight: 700;
        font-style: italic;
        text-decoration: none;

        @include bp-lt($desktop) {
            font-size: rem(15);
        }

        &:hover, &:focus {
            color: $salmon--light;

            @include bp-lt($medium) {
                color: $white;
            }
        }
    }
}

.c-nav__level2 {
    background: none;
    @include clearfix();

    @include bp-lt($medium) {
        display: block;
        position: fixed;
        width: rem(280);
        top: 0;
        left: auto;
        right: rem(-280);
        height: 100vh;
        background: $subBranding;
        z-index: $zindex--offcanvas;
        padding: 0;
        transition: $ease;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { width: 0 !important }
    }

    li {
        margin-bottom: rem(10);

        @include bp-lt($medium) {
            margin-bottom: 0;
        }

        a {
            color: $grey--darker;
            font-size: rem(14);
            font-style: italic;
            font-weight: normal;
            white-space: nowrap;
            @include clearfix();

            @include bp-lt($desktop) {
                font-size: rem(13);
            }

            @include bp-lt($medium) {
                font-size: rem(12);
                color: $white;
                height: rem(70);
                border-bottom: solid rem(1) $salmon--light;
                text-align: left;
                padding-left: $gutter;
                padding-right: rem(80);
                display: block;
            }

            &:before {
                content: '>';
                color: $salmon--menu;
                display: block;
                float: left;
                margin-right: rem(6);

                @include bp-lt($medium) {
                    display: none;
                }
            }

            .o-table {
                display: block;
                float: left;
                width: calc(100% - 15px);

                @include bp-lt($medium) {
                    display: table;
                    float: none;
                    width: 100%;
                }
            }
        }
    }

    @include bp-lt($medium) {
        top: 0;
        left: auto;
        right: rem(-280);
    }
}

[class*="c-nav__level"] {
    & > li {
        & > a {
            @include bp-lt($medium) {
                font-size: rem(12);
                color: $white;
                height: rem(70);
                border-bottom: solid rem(1) $salmon--light;
                text-align: left;
                padding-left: $gutter;
                padding-right: rem(80);
            }
        }
    }
}

.is-inViewport {
    left: auto;
    right: 0;
}

.c-nav__show {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: rem(70);
    height: rem(70);
    background: $subBranding;
    border-left: solid rem(1) $salmon--light;
    border-bottom: solid rem(1) $salmon--light;
    text-align: center;
    color: $white;
    font-size: rem(20);
    cursor: pointer;
    transition: $ease;
    z-index: $zindex--hover;

    @include bp-lt($medium) {
        display: block;
    }

    &:hover, &:focus, &.is-active {
        background: $mainBranding;
    }
}

.c-nav__hide {
    position: relative;
    display: none;
    width: 100%;
    height: rem(70);
    color: $white;
    border-bottom: solid rem(1) $salmon--light;
    text-align: center;
    letter-spacing: rem(1);
    font-size: rem(10);
    text-transform: uppercase;
    cursor: pointer;

    @include bp-lt($medium) {
        display: block;
    }

    [class*='c-fonticon'] {
        position: absolute;
        top: 0;
        left: 0;
        width: rem(70);
        height: rem(70);
        border-right: solid rem(1) $salmon--light;
        background: transparent;
        transition: $ease;

        &:before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: rem(25);
            font-size: rem(20);
        }
    }

    &:hover {
        [class*='c-fonticon'] {
            background: $mainBranding;
        }
    }
}

$sideMenu: rem(30);
.c-nav__icon {
    position: absolute;
    z-index: 310;
    right: $gutter;
    top: 0;
    bottom: 0;
    display: none;
    margin: auto;
    width: $sideMenu;
    height: $sideMenu;
    cursor: pointer;

    @include bp-lt($medium) { display: block; }

    @include bp-lt($mobileS) { top: rem(7); }

    i {
        display: block;
        text-indent: rem(-9999);
        width: $sideMenu;
        height: rem(5);
        position: relative;
        top: rem(12);
        transform: rotate(0deg);
        transition: all 0.3s ease;
        pointer-events: none;
        border-radius: rem(2);

        &:before,
        &:after {
            display: block;
            position: absolute;
            content: "";
            width: $sideMenu;
            height: rem(5);
            transition: all 0.3s ease;
            border-radius: rem(2);
        }

        &:before {
            top: rem(-10);
            transform: rotate(0deg);
        }

        &:after {
            bottom: rem(-10);
            transform: rotate(0deg);
        }
    }

    i, i:before, i:after {
        background: $mainBranding;
    }

    &.is-active {
        i {
            background-color: transparent;
            transform: rotate(180deg);

        &:before {
                top: 0;
                transform: rotate(45deg);
            }

            &:after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
    }
}
