// variables
$fontTitle : $lato;
$fontText : $lato;

$verticalRythm: 24;
$error : #D46767;
$valid : #B3D38E;

$pink--01 : #D3938E;

$mainColor : #FCF1ED;
$subColor: $pink--01;

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk{
    margin: 0px;
}

.c-cookies__root#tarteaucitronRoot {
    * { box-sizing: border-box!important; }

//--------------------------- Bandeau Cookies
    .c-cookies--bkg {
        content: '';
        position: fixed;
        top: 50%;
        left: 50%;
        background: #33353B;
        height: 100vh;
        width: 100vw;
        transform: translateY(-50%) translateX(-50%);
        opacity: 0.8;
        z-index: 100000!important;

        &[style*="display: block"] + .c-cookies__alert {
            display: block;
        }
    }


    .c-cookies__alert {
        background: $mainColor;
        max-width: calc(100vw - 20px);
        width: 774px;
        padding: 37px 75px 38px 75px;
        border: 2px solid #d3938e;
        z-index: 100001!important;
        bottom: auto;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        @include bp-lt($small) {
            padding: 18px 18px 24px 18px;
        }

        #tarteaucitronDisclaimerAlert,
        .c-cookies__alert__text,
        .c-cookies__alert__buttons {
            max-width: 100% !important;
            width: 100% !important;
            margin: 0 auto;
            padding: 0;
        }

        //title
        .c-cookies__alert__title {
            font-family: $fontTitle;
            font-size: 24px !important;
            font-weight: 700;
            letter-spacing: 0.8px;
            line-height: 1.25;
            text-align: center;
            margin: 0;
            margin-bottom: 30px;

            @include bp-lt($tablet){
                font-size: 20px !important;
                margin-bottom: 15px;
            }
        }

        //text bottom title
        #tarteaucitronDisclaimerAlertFull.c-cookies__alert__text {
            font-family: $fontTitle;
            font-size: 14px !important;
            font-weight: normal;
            line-height: 1.5;
            color: $subColor;
            text-align: center;
            float: none;

            @include bp-lt($tablet){
                font-size: 14px !important;
            }

            a, button {
                color: $subColor!important;
            }

            button {
                font-size: 14px !important;
                padding: 0;
                margin: 0;
                text-decoration: underline !important;
            }
        }

        .c-cookies__alert__buttons {
            margin-top: 36px !important;

            @include bp-lt($tablet){
                margin-top: 20px !important;
            }
        }

        .c-cookies__alert__buttons button {
            text-align: center;
            border: 2px solid $pink--01!important;
            background: $white!important;
            color: $pink--01!important;
            font-family: $fontTitle!important;
            padding: 10px 20px!important;
            margin: 10px 10px!important;
            border-radius: 0!important;
            font-size: 13px !important;
            font-weight: 900;
            letter-spacing: 1px;
            // height: 48px;

            &:hover {
                background: none !important;
            }

            &:first-child {
                margin-left: 0px!important;
            }

            &:last-child {
                margin-right: 0px!important;
            }

            @include bp-lt($xsmall){
                width: 100%;
                margin-left: 0!important;
                margin-right: 0!important;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 12px !important;
                }
            }
        }

        // hide percentage bar
        + #tarteaucitronPercentage {
            display: none !important;
        }
    }
//--------------------------- Fin Bandeau Cookies


//--------------------------- Popin Cookies
    .c-cookies__choose#tarteaucitron {
        max-width: 790px;
        // display: block !important;
        &, #tarteaucitronServices {
            // background-color: rgba($white, 0.15);
        }

        #tarteaucitronClosePanel {
            display: none;
        }

        .c-button--main[style*="opacity: 1"]{
            background: $error !important;
            border-color: $error !important;
            color: $white !important;

            &:hover {
                color: $pink--01 !important;
            }
        }

        .c-button--alt[style*="opacity: 1"]{
            background: $valid !important;
            border-color: $valid !important;
            color: $white !important;

            &:hover {
                color: $pink--01 !important;
            }
        }

        // icon close
        $size : 30px;
        .c-cookies__choose__close {
            position: absolute!important;
            right: 12px!important;
            top: 10px;
            width: $size;
            height: $size;
            opacity: 0.6;
            font-size: 0 !important;
            line-height: 0;
            text-indent: -999px;
            background: none!important;
            padding: 0!important;
            margin: 0!important;
            opacity: 1;
            transition: $ease;

            &:hover { opacity: 0.7; }

            &:before, &:after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                content: '';
                height: $size;
                width: 3px;
                background-color: $white;
            }

            &:before { transform: rotate(45deg); }

            &:after { transform: rotate(-45deg);}
        }

        // intro
        .c-cookies__choose__intro {
            padding: 0;

            .c-cookies__choose__intro__title {
                padding: 35px 20px;
                font-size: 24px;
                line-height: 24px;
                font-weight: 700;
                font-family: $fontTitle;
                color: $white;
                letter-spacing: 0.8px;
                background: #E4A9A5;

                @include bp-lt($small){
                    padding: 50px 20px;
                }
            }
        }

        .c-cookies__choose__intro__text.tarteaucitronDetails {
            margin: 18px 0px;
            padding: 0 25px;

            @include bp-lt($small){
                margin: 25px auto;
            }
        }

        // paragraphs  globals + paragraphs subtitle
        .c-cookies__choose__intro__text, p, .c-cookies__category__thumbs.tarteaucitronDetails {
            font-size: 14px !important;
            line-height: 1.7!important;
            font-family: $fontTitle!important;
            color: $black!important;
            text-align: left!important;
        }

        .tarteaucitronName {
            width: 46%;
            @include bp-lt($small){
                width: 100%;
            }
        }

        .tarteaucitronAsk {
            width: 54% !important;
            justify-content: flex-end;
            display: flex!important;

            @include bp-lt($small){
                width: 100%!important;
            }

            @include bp-lt($xmedium){
                flex-flow: column wrap;
                align-items: flex-end;

                div[class*="c-button"]{
                    margin: 5px 0 !important;
                }
            }
        }

        .tarteaucitronMainLine {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border: 0;
            padding: 20px 25px 20px 35px;
            border-top: 1px solid $mainColor;
            background: #FCF1ED;

            .tarteaucitronName {
                width: 46%;
                @include bp-lt($small){
                    width: 100%;
                }
            }

            .tarteaucitronAsk {
                display: block !important;
                margin: 0 !important;
                width: 54% !important;

                @include bp-lt($small){
                    width: 100% !important;
                    text-align: center;
                    margin-top: 20px !important;
                    display: flex !important;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: space-between;
                }

                > div {
                    margin: 0 5px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    @include bp-lt($small){
                        margin: 0 12px;
                        width: calc(50% - 12px);
                        margin: 0 12px;
                        padding: 0;
                    }

                    @include bp-lt($xsmall){
                        margin: 5px 0;
                        width: 100%;
                    }
                }
            }
        }

        div[class*="c-button"]{
            text-align: center;
            border: 2px solid $pink--01!important;
            background: $white!important;
            color: $pink--01!important;
            font-family: $fontTitle!important;
            padding: 0 20px!important;
            margin: 0 10px!important;
            border-radius: 0!important;
            font-size: 13px !important;
            line-height: 44px;
            font-weight: 900;
            letter-spacing: 1px;

            &:last-of-type {
                margin-right: 0 !important;
            }
            // height: 48px;

            &:hover {
                background: none !important;
            }

            @include bp-lt($small){
                line-height: 36px;
            }

            @include bp-lt($xsmall){
                width: 100%;
                margin: 0;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 12px !important;
                }
            }
        }

        #tarteaucitronScrollbarParent {
            height: auto !important;

            #tarteaucitronScrollbarChild{
                display: none!important;
            }

            #tac-footer,
            .tac-footer {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin: 26px 26px 40px 38px;

                a {
                    font-weight: normal;
                    text-transform: none;
                    font-size: 14px;
                    text-decoration: underline;
                }

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 350px;
                    margin: 10px 0px;
                    background-color: white;
                    border: 2px solid #d3938e;
                    color: #d3938e;
                    font-size: 13px;
                    font-weight: 900;
                    border-radius: 0px;
                    &:hover{
                        background-color: #d3938e;
                        color: white;
                    }
                }
            }

            .tarteaucitronLine button,
            .tarteaucitronLine .c-button--alt,
            .tarteaucitronLine .c-button--main {
                width: 165px;
            }

            .c-cookies__category.tarteaucitronLine {
                padding: 20px 0px 20px 0px;
                margin: 0px 25px 0px 38px;
                border: 0;
                border-top: 1px solid $mainColor;
                text-align: left !important;

                .tarteaucitronTitle {
                    font-size: 16px;
                    line-height: 16px;
                    font-family: $fontTitle;
                    font-weight: bold;
                    color: $black;
                    text-align: left !important;
                    padding: 0 !important;
                    margin-bottom: 15px;
                }
            }

            [id*="tarteaucitronServices_"] {
                padding: 0;

                .c-cookies__category__service {
                    background: none;
                    padding: 28px 0px 20px 0px;
                    margin: 0px 25px 0px 38px;
                    border-top: 1px solid rgba(#1D2E6B, 0.07);
                    border-bottom: 1px solid rgba(#1D2E6B, 0.07);
                    border-left: none!important;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: center;

                    &:first-child {
                        margin-top: 0;
                    }

                    &.c-cookies__category__service--allowedBorder {
                        border-left-color: $valid;
                    }

                    &.c-cookies__category__service--deniedBorder {
                        border-left-color: $error;
                    }

                    .tarteaucitronAsk {
                        margin-left: 0;
                        margin-right: 0;
                        width: 50%;

                        @include bp-lt($small){
                            width: 100%;
                            text-align: center;
                            margin-top: 20px;
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                            justify-content: space-between;
                        }

                        div {
                            @include bp-lt($small){
                                width: calc(50% - 12px);
                                margin: 0 12px;
                                padding: 0;

                                &:first-child {
                                    margin-left: 0;
                                }

                                &:last-child {
                                    margin-right: 0;
                                }
                            }

                            @include bp-lt($xsmall){
                                margin: 10px 0;
                                width: 100%;
                            }
                        }
                    }

                    .tarteaucitronName {
                        @include bp-lt($small){
                            width: 100%;
                        }

                        br {
                            display: none;
                        }
                    }

                    //title services
                    .tarteaucitronName b {
                        font-size: 16px;
                        line-height: 1.1;
                        font-weight: 700;
                        font-family: $fontTitle;
                        color: $black;
                        display: block;
                        width: 100%;
                    }

                    //title services
                    .tarteaucitronName span {
                        font-size: 14px;
                        line-height: 14px;
                        font-style: italic;
                        font-weight: 300;
                        font-family: $fontText;
                        color: #B7B7B7;
                        text-transform: none;
                        display: block;
                        margin-top: 7px;
                        width: 100%;
                    }

                    //title services
                    .tarteaucitronName a {
                        font-size: 13px;
                        line-height: 14px;
                        font-family: $fontText;
                        color: $black;
                        text-decoration: underline;
                        text-transform: none;

                        &:first-of-type {
                            margin-right: 20px;
                        }

                        .services-arrow {display: none;}

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
//--------------------------- Fin Popin Cookies

    .c-cookies__choose#tarteaucitronAuthorizeService {

        #tarteaucitronAuthorizeServiceContent {
            padding: 0;
        }

        .c-cookies__authorize__buttons {
            padding-bottom: 45px;

            @include bp-lt($small){
                padding-left: 40px;
                padding-right: 40px;
            }
        }

        .c-cookies__choose__intro__title {
            padding: 0;
            margin: 0;
        }

        div[class*="c-button"]{
            text-align: center;
            border: 2px solid $pink--01!important;
            background: $white!important;
            color: $pink--01!important;
            font-family: $fontTitle!important;
            padding: 0 20px!important;
            margin: 0 10px!important;
            border-radius: 0!important;
            font-size: 13px !important;
            line-height: 44px;
            font-weight: 900;
            letter-spacing: 1px;

            &:last-of-type {
                margin-right: 0 !important;
            }
            // height: 48px;

            &:hover {
                background: none !important;
            }

            @include bp-lt($small){
                line-height: 36px;
                width: 100%;
                margin: 5px 0!important;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 12px !important;
                }
            }
        }

        span {
            float: none;
            display: block;
            width: 100%;
        }

        // icon close
        $size : 30px;
        .c-cookies__choose__close {
            position: absolute!important;
            right: 12px!important;
            top: 10px;
            width: $size;
            height: $size;
            opacity: 0.6;
            font-size: 0 !important;
            line-height: 0;
            text-indent: -999px;
            background: none!important;
            padding: 0!important;
            margin: 0!important;
            opacity: 1;
            transition: $ease;

            &:hover { opacity: 0.7; }

            &:before, &:after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                content: '';
                height: $size;
                width: 3px;
                background-color: $white;
            }

            &:before { transform: rotate(45deg); }

            &:after { transform: rotate(-45deg);}
        }

        .c-cookies__choose__intro__title span:first-child {
            padding: 48px 20px;
            font-size: 24px;
            line-height: 24px;
            font-weight: 700;
            font-family: $fontTitle;
            color: $white;
            letter-spacing: 0.8px;
            background: #E4A9A5;
            margin-bottom: 40px;
        }

        .c-cookies__choose__intro__title span:last-child {
            @include bp-lt($small){
                padding: 0 20px;
            }
        }
    }
}
