// ========================================= //
// header
// ========================================= //
.o-header {
    height: rem(330);
    background: url('#{$img}c-bkg__header.jpg') no-repeat 50% 0;
    background-size: cover;
    transition: $bezier;

    @include bp-lt($medium) {
        height: rem(46);
    }

    .is-searching &, .front & {
        height: rem(545);

        @include bp-lt($medium) {
            height: rem(46);
        }
    }

}

.o-header__container {
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.c-logo__header {
    width: rem(389);
    height: rem(95);
    margin: 0 auto;
    margin-top: rem(29);
    display: block;
    transition: $ease;

    .front & {
        margin: 0 auto;
        width: rem(495);
        height: rem(124);
    }

    .is-searching & {
        width: rem(389);
        height: rem(95);
        margin-top: rem(29);
    }

    &, .front &, .is-searching & {
        @include bp-lt($medium) {
            @include stickyLogo();
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}
