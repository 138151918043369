// ========================================= //
// links
// ========================================= //
.c-link--more {
    color: $mainFont;
    font-size: rem(12);
    font-weight: 900;
    text-transform: uppercase;
    padding-bottom: rem(5);
    box-shadow: inset 0 rem(-3) 0 $mainBranding;
    text-decoration: none;

    @include bp-lt($small) {
        font-size: rem(10);
    }
}
