// ========================================= //
// section
// ========================================= //
[class*="c-section"] {
    padding-top: rem(45);
    padding-bottom: rem(45);
    border-top: rem(1) solid $grey--light;

    @include bp-lt($medium) {
        padding-top: rem(30);
        padding-bottom: rem(30);
    }

    @include bp-lt($small) {
        padding-top: rem(15);
        padding-bottom: rem(15);
    }

    &[class*="--grey"] {
        background-color: $grey--lighter;
    }

    &.c-block__section {
        padding-bottom: rem(25);

        @include bp-lt($small) {
            padding-bottom: rem(5);
        }

        .o-paginate {
            margin-bottom: rem(15);
        }
    }
}
