// ========================================= //
// typos
// ========================================= //
body {
    font-family: $lato;
    color: $mainFont;
    font-weight: 300;
    font-size: rem(16);
}

p {
    font-weight: 300;
    line-height: rem(30);

    @include bp-lt($small) {
        font-size: rem(13);
        line-height: rem(22);
        text-align: justify;
    }
}

a {
    color: $salmon--darker;
    text-decoration: underline;
    transition: $ease;

    &:hover, &:focus {
        color: $mainBranding;
    }
}

h1 {
    color: $grey--darker;
    font-size: $h1FontSize;
    font-family: $quattrocento;
    position: relative;
    padding: 0 rem(25);
    display: inline-block;

    @include bp-lt($medium) {
        font-size: rem(30);
        padding: 0 rem(20);
    }

    @include bp-lt($small) {
        font-size: rem(24);
        line-height: rem(28);
    }

    &:before, &:after {
        font-family: $iconFont;
        content: '\EA06';
        display: block;
        color: $salmon--light;
        font-size: rem(30);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include bp-lt($xmedium) {
            top: 0;
            transform: none;
        }

        @include bp-lt($small) {
            position: static;
        }
    }

    &:before {
        left: rem(-25);

        @include bp-lt($small) { display: none; }
    }

    &:after {
        right: rem(-25);
    }
}

h2 {
    color: $salmon--darker;
    font-size: $h2FontSize;
    font-family: $quattrocento;

    @include bp-lt($medium) { font-size: rem(22); }

    @include bp-lt($small) { font-size: rem(18); }
}

h3 { font-style: italic; }

h3, h4 {
    font-size: rem(18);
    font-family: $lato;
}

h4 {
    font-weight: 300;
    color: $salmon--darker;

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: rem(5);
        height: rem(5);
        border-radius: 50%;
        background: $salmon--light;
        margin-right: rem(20);
    }
}

h5, h6 {
    font-size: rem(15);
    font-family: $lato;
}

h5 {
    font-weight: 600;
    color: $salmon--darker;
}

h6 {
    color: $salmon--light;
    font-style: italic;
}


hr {
    border-color: $grey--light;
}

blockquote {
    position: relative;
    color: $mainFont;
    border: rem(2) solid $blockquoteBorder;
    border-left: none;
    border-right: none;
    font-style: italic;
    font-family: $lato;
    font-size: rem(16);
    padding: rem(40) rem(125);
    text-align: center;

    @include bp-lt($medium) { padding: rem(30) rem(100); }

    @include bp-lt($small) { padding: rem(15) rem(50); }

    &:before {
        content: '\EA10';
        font-family: $iconFont;
        position: absolute;
        top: rem(-50);
        left: rem(15);
        font-size: rem(88);
        color: $salmon--light;
        font-style: normal;

        @include bp-lt($medium) {
            font-size: rem(60);
        }

        @include bp-lt($small) {
            font-size: rem(40);
            top: rem(-25);
        }
    }
}

table {
    max-width: 100%;
    width: 100%;
    overflow-x: scroll;
    margin-bottom: $gutter;
    border: rem(1) solid $tableBorder;

    td, th {
        border: rem(1) solid $tableBorder;
        font-weight: normal;
    }

    thead {
        background: none;

        tr {
            th {
                font-size: rem(16);
                font-weight: 900;
            }
        }
    }

    th {
        background: $salmon--light;
        font-family: $quattrocento;
    }

    tbody {
        td {
            font-size: rem(15);
            font-family: $lato;
            font-weight: 300;
        }
    }

    tr:nth-of-type(even) {
        background: $grey--lighter;
    }
}

.c-overflow__table {
    width: 100%;
    overflow-y: auto;
    _overflow: auto;
    margin: 0 0 $gutter;

    &::-webkit-scrollbar
    {
        -webkit-appearance: none;
        width: rem(14);
        height: rem(14);
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: rem(8);
        border: rem(3) solid $white;
        background-color: rgba(0, 0, 0, .3);
    }

    table {
        margin-bottom: 0;
    }
}
