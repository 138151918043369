// ========================================= //
// titles
// ========================================= //
[class*="c-title"] {
    font-family: $quattrocento;
    font-style: normal;

    &[class*="--medium"] {
        font-size: rem(24);
        font-weight: 900;
        margin-bottom: rem(40);

        @include bp-lt($medium) {
            margin-bottom: rem(30);
        }

        @include bp-lt($small) {
            font-size: rem(20);
            margin-bottom: rem(15);
        }
    }

    &[class*="--main"] {
        font-size: rem(42);
        margin-bottom: rem(40);
        position: relative;
        padding: 0 rem(25);
        display: inline-block;

        @include bp-lt($medium) {
            font-size: rem(30);
            margin-bottom: rem(25);
        }

        @include bp-lt($small) {
            font-size: rem(24);
            line-height: rem(28);
            margin-bottom: rem(15);
        }

        &:before, &:after {
            font-family: $iconFont;
            content: '\EA06';
            display: block;
            color: $salmon--light;
            font-size: rem(30);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include bp-lt($xmedium) {
                top: 0;
                transform: none;
            }

            @include bp-lt($small) {
                position: static;
            }
        }

        &:before {
            left: rem(-25);

            @include bp-lt($small) { display: none; }
        }

        &:after {
            right: rem(-25);
        }
    }

    a { text-decoration: none; }

    span.main-font { color: $mainFont; }
    
    span { color: $salmon--darker; }
}

.c-title__baseline {
    font-size: rem(15);
    font-style: italic;
    color: $grey;
    font-family: $lato;
}

.c-subTitle {
    display: block;
    font-size: rem(24);
    font-family: $quattrocento;
    text-decoration: none;
    color: $salmon--darker;

    @include bp-lt($small) { font-size: rem(18); }
}
