.c-social {
    position: absolute;
    top: 0;
    right: $gutter;
    list-style: none;
    margin: 0;

    .front & {
        top: rem(-70);
        transition: top 0.3s ease;
    }

    .is-searching & {
        top: rem(10);
    }

    li {
        display: inline-block;
        margin: 0 rem(5);

        &:first-child { margin-left: 0; }

        &:last-child { margin-right: 0; }
    }

    a {
        display: block;
        width: rem(31);
        height: rem(31);
        border-radius: 50%;
        text-align: center;
        border: rem(1) solid $salmon--light;
        color: $mainFont;
        transition: $ease;
        font-size: rem(14);
        text-decoration: none;        

        @include bp-lt($medium) {
            border-color: $salmon--dark;
            color: darken($salmon--darker, 5%);
        }

        &:hover, &:focus {
            color: $white;
            background-color: $salmon--light;
            border-color: $salmon--light;
        }
    }
}
