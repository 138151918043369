// ========================================= //
// breadcrumb
// ========================================= //
.c-breadcrumb {
    padding-top: rem(10);
    padding-bottom: rem(10);
    border-bottom: rem(1) solid $grey--light;

    & + [class*="c-section"] { border-top: none; }

    ul {
        list-style: none;
        margin: 0;
    }

    li {
        display: inline-block;
        font-size: rem(12);

        @include bp-lt($small) {
            display: none;

            &:first-of-type, &:nth-child(3), &:last-of-type { display: inline-block; }            
        }

        &:after {
            content: '>';
            font-weight: normal;
            margin: 0 rem(5);
        }

        &:first-child { margin-left: 0; }

        &:last-child {
            &:after { display: none; }
        }

        a {
            text-decoration: none;
            color: $mainFont;
            font-weight: 900;
            font-size: rem(12);

            &:hover, &:focus { color: $mainBranding; }
        }
    }
}
