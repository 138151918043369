// ========================================= //
// inputs
// ========================================= //
input:not([type]),
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
select,
.c-input {
    background: $white;
    box-shadow: none;
    border-radius: rem(3);
    border: rem(1) solid $inputBorder;
    margin-bottom: rem(25);
    font-size: rem(16);
    font-family: $lato;
    font-style: italic;
    color: $mainFont;
    height: rem(48);
    padding-left: rem(20);
    padding-right: rem(20);

    @include bp-lt($small) {
        height: rem(40) !important;
        margin-bottom: rem(0) !important;
        font-size: rem(14) !important;
    }

    @include placeholder-color($grey);

    &:hover, &:focus {
        border-color: $inputBorder;
        background-color: $white;
    }
}

.c-input__holder {
    position: relative;
    height: rem(48);
    margin-bottom: rem(20);

    @include bp-lt($small) {
        height: rem(40);
        margin-bottom: rem(10);
    }

    &.is-overflow { overflow-x: hidden; }

    label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: $zindex--hover;
        text-align: left;
        cursor: text;
        transition: $ease;
        font-style: italic;
        font-size: rem(15);
        opacity: 0.5;
        padding-left: rem(15);
        padding-right: rem(15);
        @include clearfix;
        white-space: nowrap;
        pointer-events: none;

        &.is-required {
            &:before {
                content: '*';
                display: inline-block;
                vertical-align: middle;
                color: $mainBranding;
                font-style: italic;
                margin-right: rem(5);
            }
        }
    }

    input,
    select,
    textarea {
        position: relative;
        width: 100%;
        z-index: $zindex--content;
        margin: 0;
        font-size: rem(14);
        font-style: italic;
        box-shadow: none;
        border: none;
        padding-left: rem(15);
        padding-right: rem(15);
        padding-top: rem(22);

        &, &:hover, &:focus {
            background: $white;
            border: rem(1) solid $inputBorder;
        }
    }

    select {
        @include bp-lt($small) {
            padding-top: rem(12);
            padding-bottom: 0;
        }

        &, &:hover, &:focus {
            background: $white url(#{$img}c-bkg__select.svg) no-repeat 100% 50%;
        }
    }

    &.is-focus {
        label {
            font-size: rem(8);
            font-weight: 700;
            text-transform: uppercase;
            font-style: normal;
            height: rem(16);
            transform: translateY(-12px);
        }
    }

    &.is-textarea {
        height: rem(170);

        textarea {
            height: rem(170);
            resize: none;

            @include bp-lt($small) { height: rem(170) !important; }
        }

        label {
            top: rem(25);
        }
    }
}

input[type="checkbox"],
input[type="radio"] {
    &:checked, &:not(:checked) {
        position: absolute;
        left: rem(-9999);
        opacity: 0;
        pointer-events: none;

        & + label {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            position: relative;
            padding-left: rem(30);
            font-size: rem(14);
            font-weight: 300;
            line-height: 1em;

            &:before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: rem(17);
                height: rem(17);
                background: $white;
                border: solid rem(1) $grey--light;
                transition: $ease;
                border-radius: rem(3);
            }

            &:after {
                content: '';
                position: absolute;
                display: block;
                transition: $ease;
                border-radius: rem(1);
            }
        }

        &:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0);
            transition: $ease;
        }

        &:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }

        &:hover {
            & + label {
                &:before {
                    transition: $ease;
                }
            }
        }
    }
}

input[type="checkbox"] {
    &:checked + label:after,
    &:not(:checked) + label:after {
        width: rem(9);
        height: rem(9);
        top: rem(4);
        left: rem(4);
        background: $subBranding;
    }
}

input[type="radio"] {
    display: inline-block;

    &:checked, &:not(:checked) {
        & + label {
            margin-bottom: rem(14);
            top: rem(4);
            position: relative;
        }

        & + label:before {
            top: rem(-2);
        }

        & + label:before,
        & + label:after {
            border-radius: 50%;
        }

        & + label:after {
            width: rem(9);
            height: rem(9);
            top: rem(2);
            left: rem(4);
            background: $subBranding;
        }
    }
}

.c-input {
    padding: 0;
    padding-left: rem(15);
    padding-right: rem(15);
}
