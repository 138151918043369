.c-share {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $gutter;
    display: block;
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    border: rem(1) solid $grey--light;
    color: $mainFont;
    text-decoration: none;

    @include bp-lt($small) {
        position: static;
        transform: none;
        margin: rem(5) auto 0;
        width: rem(25);
        height: rem(25);
        font-size: rem(10);
    }

    &:hover, &:focus {
        color: $mainBranding;
    }
}
