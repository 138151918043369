// ========================================= //
// footer
// ========================================= //
.o-footer {
    box-shadow: 0 rem(-4) rem(8) rgba($black, 0.1);
    padding-top: rem(10);
    padding-bottom: rem(10);

    @include bp-lt($small) {
        text-align: center;
        padding-bottom: 0;
    }

    &.is-fixed {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .c-social {
        position: static;
        text-align: center;

        @include bp-lt($small) {
            display: inline-block;
        }

        li {
            @include bp-lt($small) { margin: 0; }
        }

        a {
            border: none;
            font-size: rem(15);

            &:hover, &:focus {
                color: $mainBranding;
                background: none;
            }
        }
    }
}

.c-footer__nav {
    left: $gutter;
    top: rem(3);

    ul {
        margin: 0;
        list-style: none;
    }

    li {
        display: inline-block;

        &:after {
            content: '|';
            color: $mainBranding;
            margin: 0 rem(5) 0 rem(7);
        }

        &:last-child {
            &:after { display: none; }
        }
    }

    a {
        font-size: rem(15);
        text-decoration: none;
        color: $mainFont;

        @include bp-lt($small) { font-size: rem(13); }

        &:hover, &:focus {
            color: $mainBranding;
        }
    }
}

.c-footer__nav, .c-dbm {
    position: absolute;

    @include bp-lt($small) { position: static; }
}

.c-dbm {
    top: rem(-5);
    right: $gutter;
    font-size: rem(30);
    text-decoration: none;

    @include bp-lt($small) {
        display: inline-block;
        margin-left: rem(5);
    }

    [class*="c-fonticon"] {
        &:before {
            @include bp-lt($small) { top: rem(2); }
        }
    }
}
