// ========================================= //
// ui
// ========================================= //

::-moz-selection { color: $selectionColor;  background: $selectionBackground; }
::selection      { color: $selectionColor;  background: $selectionBackground; }

* {
    outline-color: $mainBranding;

    &:focus {
        outline: none;
    }
}

.o-table {
    display: table;
    width: 100%;
    height: 100%;
}

[class*='o-table__cell'] {
    display: table-cell;

    &[class*='--valignMiddle'] {
        vertical-align: middle;

        &[class*='--tabletDown'] {
            vertical-align: top;

            @include bp-lt($tablet) {
                vertical-align: middle;
            }
        }
    }

    &[class*='--valignBottom'] {
        vertical-align: bottom;
    }
}

.row {
    max-width: $layoutWidth;

    &.row--full {
        max-width: 100%;
    }

    .row {
        margin: 0 -1*$gutter;
    }

    [class*='column'] {
        padding-left: $gutter;
        padding-right: $gutter;
    }
}

[class*="c-fonticon"] {
    &:before {
        position: relative;
        top: rem(3);
    }
}

main {
    @include bp-lt($small) { padding-top: rem(35); }

    .not-front & {
        padding-top: rem(85);

        @include bp-lt($medium) {
            padding-top: rem(45);
        }

        @include bp-lt($small) {
            padding-top: rem(81);
        }
    }
}
