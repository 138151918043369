.c-tab__list {
    background: $grey--lighter;
    height: rem(70);
    border-top: rem(1) solid $grey--light;
    border-bottom: rem(1) solid $grey--light;

    @include bp-lt($small) {
        display: table;
        width: 100%;
    }

    ul {
        list-style: none;
        margin: 0;
    }

    .c-product__characteristics {
        border-bottom: rem(1) solid $grey--light;
        border-top: none;

        @include bp-lt($xmedium) {
            font-size: rem(13);
        }
    }

    .c-product__image {
        img {
            position: static;
            max-width: 100%;
            top: 0;
            left: 0;
            transform: none;
        }
    }
}

.c-tab__item {
    display: inline-block;
    height: rem(69);
    padding: 0 rem(30);

    @include bp-lt($large) { padding: 0 rem(20); }

    @include bp-lt($xmedium) { padding: 0 rem(5); }

    @include bp-lt($small) {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 1%;
    }

    a {
        color: $mainFont;
        font-size: rem(16);
        text-decoration: none;
        display: block;
        height: 100%;
        position: relative;

        @include bp-lt($medium) { font-size: rem(14); }

        @include bp-lt($small) { font-size: rem(13); }

        &:hover, &:focus {
            box-shadow: inset 0 rem(-3) 0 $mainBranding;
        }

        &.is-active {
            font-weight: 900;
            box-shadow: inset 0 rem(-3) 0 $mainBranding;
        }
    }
}

.c-tab__product {
    background: $white;
    border: rem(1) solid $grey--light;
    border-top: none;
    position: relative;

    .c-product__image {
        position: absolute;
        top: rem(69);
        left: rem(-1);
        width: calc(100% + 2px);
        border: rem(1) solid $grey--light;
        border-top: none;
        height: auto;

        @include bp-lt($small) { position: static; }

        img {
            position: static;
            top: 0;
            left: 0;
            transform: none;
            max-width: 100%;
        }
    }

    .c-product__characteristics {
        @include bp-lt($xmedium) { position: static; }
    }
}

.c-tab__content {
    padding: rem(30);
    min-height: rem(380);

    @include bp-lt($small) {
        min-height: 0;
        padding: rem(15) $gutter rem(20);
    }    

    .c-title--medium { margin-bottom: 0; }
}
