// ========================================= //
// buttons
// ========================================= //
[class*="c-button"] {
    display: inline-block;
    height: rem(48);
    width: auto;
    min-width: rem(160);
    padding: 0 rem(15);
    margin: 0;
    border-radius: rem(3);
    border: none;
    font-size: rem(15);
    text-transform: uppercase;
    font-family: $lato;
    font-weight: 700;
    letter-spacing: rem(2);
    transition: $ease;
    text-align: center;

    @include bp-lt($small) {
        font-size: rem(14);
        height: rem(40);
    }

    &[class*="--main"] {
        color: $white;
        background-color: $mainBranding;

        &:hover, &:focus {
            background-color: $subBranding;
        }
    }

    &[class*="--sub"] {
        color: $white;
        background-color: $subBranding;

        &:hover, &:focus {
            background-color: $mainBranding;
        }
    }
}

.c-button__advancedSearch {
    background: none;
    font-size: rem(12);
    color: $salmon--darker;
    padding: 0;
    height: auto;
    width: rem(195);
    outline: none;
    position: relative;
    z-index: 1;
    transition: $ease;
    white-space: nowrap;

    &:hover, &:focus {
        background: none;
        color: $salmon--light;
    }

    @include bp-lt($small) {
        float: right;
        margin-right: rem(185);
    }

    &, &.is-active {
        @include bp-lt($xmedium) {
            color: transparent !important;
            width: rem(52) !important;
            min-width: 0;
            background: darken($salmon--darker, 5%);
            border-radius: 0;
            position: relative;
        }

        span {
            @include bp-lt($xmedium) { display: none !important; }
        }
    }

    &:before {
        @include bp-lt($xmedium) {
            content: '\EA11';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: 0 auto;
            font-family: $iconFont;
            color: $white;
            font-size: rem(20);
            font-weight: normal;
        }
    }


    &.is-active {
        width: rem(20);
        height: rem(20);
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;

        @include bp-lt($medium) {
            width: rem(195);
            height: auto;
        }

        .not-front & {
            height: auto;
            width: rem(195);
        }

        span {
            top: 0;
            transform: rotate(45deg);

            @include bp-lt($medium) {
                top: rem(-2);
                transform: none;
            }

            .not-front & {
                top: rem(-2);
                transform: none;
            }
        }
    }

    span {
        width: rem(21);
        height: rem(21);
        border-radius: 50%;
        background: $salmon--light;
        display: inline-block;
        color: $white;
        font-size: rem(10);
        vertical-align: middle;
        margin-right: rem(10);
        position: relative;
        top: rem(-2);
        transition: $ease;

        &:before {
            left: rem(1);
            top: 50%;
            transform: translateY(-50%);
            height: rem(11);
        }
    }
}

.c-action__gototop {
    $side : rem(65);
    $side--medium : rem(50);
    $side--small : rem(40);

    position: fixed;
    z-index: 200;
    width: $side;
    height: $side;
    bottom: rem(80);
    right: $gutter;
    background: $salmon--light;
    border-radius: 50%;
    margin: 0;
    opacity: 0;
    transform: translateY(100%);
    pointer-events: none;
    padding: 0;
    z-index: $zindex--fixed - 1;
    font-size: rem(30);
    transition: $ease;
    outline: none;

    &:hover, &:focus { background: $salmon--light; }

    @include bp-lt($medium) {
        width: $side--medium;
        height: $side--medium;
        font-size: rem(20);
    }

    @include bp-lt($small) {
        width: $side--small;
        height: $side--small;
    }

    &.is-visible {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
        background: $salmon--light;

        &:hover, &:focus { background: $mainBranding; }
    }
}
