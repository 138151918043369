.easy-autocomplete {
    display: inline-block;
    width: calc(100% - 191px) !important;
    position: relative;

    @include bp-lt($medium) {
        width: calc(100% - 53px) !important;
    }
}

.easy-autocomplete-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: $white;
    z-index: 70;
    box-shadow: 0 rem(2) rem(5) rgba($black, 0.1);

    ul {
        list-style: none;
        margin: 0;

        li {
            background: $white;
            transition: $ease;
            cursor: pointer;

            &:hover {
                background: $salmon--light;
            }
        }
    }
}

.c-autocomplete { padding: $gutter; }

.c-autocomplete__picture, .c-autocomplete__details {
    display: inline-block;
    vertical-align: middle;
}

.c-autocomplete__picture {
    width: rem(60);

    img { border: rem(1) solid $inputBorder; }
}

.c-autocomplete__details {
    margin-left: rem(-4);
    width: calc(100% - 60px);
    padding: 0 rem(20);
}

.c-autocomplete__title {
    font-family: $quattrocento;
    font-weight: 900;
    font-size: rem(16);
}

.c-autocomplete__type {
    font-style: italic;
    font-size: rem(14);
}
