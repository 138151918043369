// ========================================= //
// clearfix
// ========================================= //

@mixin clearfix {
    &:after{
        content:"";
        display:table;
        clear:both;
    }

    &:before, &:after {
        content: "";
        display: table;
    }

    &:after { clear: both; }
    & { *zoom:1; }
}
