// ========================================= //
// colors
// ========================================= //

$white                  : #ffffff !default;
$black                  : #000000 !default;
$error                  : #da4f4f !default;
$grey                   : #9d9da6 !default;
$red                    : #ba2327 !default;
$orange                 : #ffa279 !default;

$salmon                 : #e4a9a5 !default;
$salmon--light          : #f2c7c4 !default;
$salmon--lighter        : #f9dcda !default;
$salmon--dark           : #d59691 !default;
$salmon--darker         : #d3938e !default;
$salmon--menu           : #d79893 !default;

$grey--lighter          : #f8f8f8 !default;
$grey--light            : #eaeaea !default;
$grey--dark             : #dadada !default;
$grey--darker           : #232323 !default;
$grey--menu--separator  : #e9e7e7 !default;

$selectionColor         : $white !default;
$selectionBackground    : $salmon !default;

$mainBranding           : $salmon !default;
$subBranding            : $salmon--dark !default;

$mainFont               : $grey--darker !default;

$inputBorder            : $grey--light !default;

$blockBorder            : #f2f2f2 !default;

$productBorder          : #f0e9eb !default;

$tableBorder            : #d2d5d9 !default;
$blockquoteBorder       : #efefef !default;
