// ========================================= //
// content
// ========================================= //
.c-content__introduction {
    @include clearfix;
    margin-bottom: rem(40);
}

.c-content__picture {
    text-align: center;

    img { border: rem(1) solid $grey--light; }

    @include bp-lt($small) { margin-bottom: rem(10); }
}

.c-content__description {
    padding-left: rem(20);
    font-weight: 300;
    line-height: rem(30);

    @include bp-lt($small) {
        padding-left: 0;
        font-size: rem(13);
        line-height: rem(22);
        text-align: justify;
    }
}
