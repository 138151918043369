// ========================================= //
// form
// ========================================= //
.c-form__button {
    margin-top: rem(30);
    text-align: center;

    @include bp-lt($medium) { margin-top: rem(20); }
    @include bp-lt($xmedium) { margin-bottom: rem(150); }
    @include bp-lt($small) { margin-top: rem(10); margin-bottom: 0; }

    [class*="c-button"] {
        background-color: $salmon--darker;

        &:hover, &:focus { background-color: $mainBranding; }
    }
}

.c-form__contact {
    margin-top: rem(60);

    @include bp-lt($medium) { margin-top: rem(40); }
    @include bp-lt($small) { margin-top: rem(20); }
}
