.c-advancedSearch {
    background: $salmon--light;
    height: 0;
    position: relative;
    top: rem(-12);
    transition: $bezier;
    overflow: hidden;
    opacity: 0;
    border-bottom: rem(1) solid $white;

    .c-advancedSearch__wrapper.is-open & {
        height: rem(320);
        opacity: 1;

        .not-front & {
            height: auto;
            overflow: auto;
            top: 50%;
            transform: translateY(-50%);
            border-bottom: none;
            box-shadow: 0 0 rem(10) rgba($black, 0.3);
            padding-bottom: rem(20);

            @include bp-lt($medium) {
                height: auto;
                overflow: auto;
                top: 50%;
                transform: translateY(-50%);
                border-bottom: none;
                box-shadow: 0 0 rem(10) rgba($black, 0.3);
                padding-bottom: rem(20);
            }

            @include bp-lt($small) {
                overflow: auto;
                height: 90%;
            }
        }

        @include bp-lt($medium) {
            height: auto;
            overflow: auto;
            top: 50%;
            transform: translateY(-50%);
            border-bottom: none;
            box-shadow: 0 0 rem(10) rgba($black, 0.3);
            padding-bottom: rem(20);
        }

        @include bp-lt($small) {
            overflow: auto;
            height: 90%;
        }
    }

    input,
    select,
    textarea,
    .c-input {
        &, &:hover, &:focus { border-color: $salmon--dark; }
    }
}

.c-advancedSearch__title {
    text-align: center;
    font-weight: 900;
    font-family: $quattrocento;
    font-size: rem(22);
    padding: rem(30) 0;

    @include bp-lt($medium) { padding: rem(20) 0; }
}

.c-advancedSearch__footer {
    border-top: rem(1) solid $salmon--lighter;
    padding-top: rem(20);

    [class*="c-button"] {
        font-size: rem(14);
        line-height: rem(24);

        [class*="c-fonticon"] {
            color: $salmon--light;
            font-size: rem(20);
            margin-right: rem(20);

            &:before { font-weight: 900; }
        }
    }
}

.c-advancedSearch__wrapper {
    @include bp-lt($medium) {
        padding: rem(20) rem(20);
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: rgba($mainBranding, 0.8);
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        transition: $ease;
    }

    .not-front & {
        padding: rem(20) rem(20);
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: rgba($mainBranding, 0.8);
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        transition: $ease;

        &.is-open {
            opacity: 1;
            visibility: visible;

            .c-advancedSearch__close { display: block; }
        }
    }

    &.is-open {
        @include bp-lt($medium) {
            opacity: 1;
            visibility: visible;
        }

        .c-advancedSearch__close {
            @include bp-lt($medium) { display: block; }
        }
    }
}

.c-advancedSearch__close {
    position: fixed;
    z-index: 999;
    top: $gutter;
    right: $gutter;
    display: none;
    background: $grey;
    width: rem(22);
    height: rem(22);
    border-radius: 50%;
    color: $white;
    font-size: rem(10);
    padding: rem(5) 0 0 rem(5);
    box-shadow: 0 0 rem(5) rgba($salmon--darker, 0.9);

    span {
        display: block;
        transform: rotate(45deg);

        &:before {
            left: rem(1);
        }
    }
}
