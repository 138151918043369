// ========================================= //
// blocks
// ========================================= //

.c-block__list {
    .c-block__item {
        margin-bottom: rem(20);
    }
}

.c-block {
    position: relative;
    border: rem(1) solid $blockBorder;
    background: $white;
    height: 100%;
}

.c-block__shadow {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: rem(4);
    width: calc(100% - 10px);

    &:before, &:after {
        content: '';
        display: block;
        width: 50%;
        height: rem(10);
        border-radius: rem(20);
        box-shadow: 0 rem(6) rem(10) rgba($black, 0.1);
    }

    &:before {
        float: left;
        transform: rotate(-2deg);
    }
    &:after {
        float: right;
        transform: rotate(2deg);
    }
}

.c-block__link {
    position: relative;
    z-index: 1;
    display: block;
    text-decoration: none;
    background: $white;

    &:hover, &:focus {
        .c-block__type {
            height: 100%;
            width: 100% !important;
        }
    }
}

.c-block__type {
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    text-transform: uppercase;
    font-size: rem(16);
    height: rem(60);
    max-width: 100%;
    padding-left: rem(15);
    padding-right: rem(15);
    background: rgba($salmon--light, 0.8);
    letter-spacing: rem(1);
    text-align: center;
    transition: $ease;

    @include bp-lt($medium) {
        font-size: rem(13);
    }
}

.c-block__title {
    min-height: rem(70);
    border-top: rem(1) solid $blockBorder;
    text-align: center;
    font-family: $quattrocento;
    color: $mainFont;
    font-size: rem(18);
    font-weight: 900;
    padding-left: $gutter;
    padding-right: $gutter;

    @include bp-lt($medium) {
        font-size: rem(15);
    }
}
