// ========================================= //
// components.magnificpopup
// ========================================= //

/* start state */
.my-mfp-zoom-in .mfp-image-holder,
.my-mfp-zoom-in .mfp-iframe-holder {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .mfp-image-holder,
.my-mfp-zoom-in.mfp-ready .mfp-iframe-holder {
    opacity: 1;
    transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .mfp-image-holder,
.my-mfp-zoom-in.mfp-removing .mfp-iframe-holder {
    transform: scale(0.8);
    opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}

.c-magnificpopup {
    position: relative;
    display: block;

    &:before {
        content: '\EA05';
        font-family: $iconFont;
        position: absolute;
        bottom: rem(20);
        right: rem(20);
        font-size: rem(20);
        font-weight: 900;
        color: $grey--darker;        
        z-index: 1;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: rem(17);
        right: rem(12);
        width: rem(35);
        height: rem(35);
        border-radius: 50%;
        transform: scale(0);        
    }

    &:before, &:after { transition: $ease; }

    &:hover {
        &:before {
            color: $white;
        }

        &:after {            
            background: $subBranding;            
            transform: scale(1);
        }
    }
}