.c-product__cardWrapper {
    margin-bottom: rem(20);
    outline: none;

    a {
        text-decoration: none;

        .c-product__details, .c-product__family {
            color: $mainFont;
        }

        .c-product__family {
            span {
                color: $salmon;
            }
        }
    }
}

.c-product__card {
    border: rem(1) solid $productBorder;
    background: $white;
    height: 100%;
    position: relative;

    @include bp-lt($xmedium) {
        padding-bottom: rem(69);
    }

    .o-table { table-layout: fixed; }
}

.c-product__image, .c-product__description {
    display: inline-block;
    vertical-align: top;
    height: 100%;

    @include bp-lt($xmedium) {
        display: block;
        height: auto;
    }
}

.c-product__description {
    width: rem(340);
    margin-left: rem(-5);
    position: relative;
    left: rem(1);

    @include bp-lt($large) { width: rem(250); }

    @include bp-lt($xmedium) {
        width: 100%;
        position: static;
        margin: 0;
    }
}

.c-product__image {
    width: calc(100% - 340px);
    text-align: center;
    border-right: rem(1) solid $productBorder;
    position: relative;
    overflow: hidden;

    @include bp-lt($large) { width: calc(100% - 250px); }

    @include bp-lt($xmedium) {
        width: 100%;
        border-right: none;
        border-bottom: rem(1) solid $productBorder;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: none;

        @include bp-lt($xmedium) {
            position: static;
            top: 0;
            left: 0;
            transform: none;
            max-width: 100%;
        }
    }
}

.c-product__characteristics {
    border-top: rem(1) solid $productBorder;
    height: rem(69);

    @include bp-lt($xmedium) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

.c-product__sex, .c-product__family {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.c-product__sex {
    color: $salmon--light;
    width: rem(65);
    text-align: center;
    font-size: rem(25);
    position: relative;

    @include bp-lt($small) {
        width: rem(40);
        font-size: rem(20);
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: rem(20);
        width: rem(1);
        background: $grey--light;
    }
}

.c-product__family {
    font-weight: normal;
    font-style: italic;
    margin-left: rem(-4);
    width: calc(100% - 66px);
    padding: 0 rem(20);

    @include bp-lt($small) {
        width: calc(100% - 41px);
        font-size: rem(13);
    }
}

.c-product__heading {
    padding: rem(10) rem(40);
    height: calc(100% - 70px);

    @include bp-lt($xmedium) {
        height: 100%;
        padding: rem(10) $gutter;
        text-align: center;
    }
}

.c-product__title {
    a {
        text-decoration: none;
        font-family: $quattrocento;
        font-size: rem(21);
        font-weight: 900;
        color: $mainFont;

        @include bp-lt($small) {
            font-size: rem(18);
        }

        &:hover, &:focus {
            color: $mainBranding;
        }
    }
}

.c-product__brand {
    text-decoration: none;
    font-style: italic;
    font-weight: normal;
}

.c-product__details {
    margin-top: rem(25);
    font-style: italic;
    font-weight: normal;
}

.js-product__equalizer {
    @include bp-lt($xmedium) {
        height: auto !important;
    }
}
