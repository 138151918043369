.c-list__group {
    margin-bottom: rem(20);
}

.c-list {
    margin-bottom: rem(5);

    &:last-child {
        .c-list__content {
            @include bp-lt($small) {
                margin-bottom: 0;
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;

        @include bp-lt($small) { text-align: center; }
    }

    li {
        display: inline-block;        

        @include bp-lt($small) { margin-left: 0; }

        &:after {
            content: '|';
            margin: 0 rem(5);
        }

        &:first-child { margin-left: 0; }

        &:last-child {
            &:after { display: none; }
        }
    }

    li, a {
        font-size: rem(15);
    }
}

.c-list__label, .c-list__content {
    display: inline-block;
    vertical-align: top;

    @include bp-lt($small) { text-align: center; }
}

.c-list__label {
    font-weight: 900;
    width: rem(200);

    @include bp-lt($small) { width: 100%; }

    &:after {
        content: ':';
    }
}

.c-list__content {
    margin-left: rem(-4);
    width: calc(100% - 201px);

    @include bp-lt($small) {
        width: 100%;
        margin-left: 0;
        margin-bottom: rem(10);
    }

    img {
        vertical-align: middle;
        position: relative;
        top: rem(-2);
        margin-right: rem(10);
        border: rem(1) solid $grey--light;
    }
}
