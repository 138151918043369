.c-pyramide {
    height: rem(288);
    width: 100%;
    background: url(#{$img}c-pyramide.png) no-repeat left top;
    padding-left: rem(280);
    margin-bottom: rem(20);

    @include bp-lt($xmedium) {
        padding-left: 0;
        max-width: rem(341);
    }

    @include bp-lt($small) {
        margin: 0 auto rem(20);
        background-position: 50% top;
    }

    @include bp-lt($mobileS) {
        height: rem(250);
        background-size: contain;
    }

    .c-list {
        @include bp-lt($xmedium) {
            background: rgba($white, 0.8);
        }
    }
}

.c-pyramide__area {
    height: rem(95);
    position: relative;

    @include bp-lt($xmedium) { text-align: center; }

    @include bp-lt($mobileS) { height: rem(78); }

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: rem(90);
        height: rem(1);
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background: $grey--dark;

        @include bp-lt($xmedium) { display: none; }
    }

    &:nth-child(1) {
        &:before {
            left: rem(-110);
        }
    }

    &:nth-child(2) {
        padding-left: rem(60);

        @include bp-lt($xmedium) { padding-left: 0; }

        &:before {
            left: rem(-50);
        }
    }

    &:nth-child(3) {
        padding-left: rem(110);

        @include bp-lt($xmedium) { padding-left: 0; }
    }

    ul {
        margin: 0;
        list-style: none;
    }
}

.c-pyramide__label {
    font-weight: 900;
    line-height: rem(16);
    position: relative;
}
